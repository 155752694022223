@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    scroll-behavior: smooth;
}

.container {
    padding: 0 1rem;
}

@media (min-width: 1536px) {
    .container {
        max-width: 1280px;
    }
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}